module services {
    export module tariff {
        export class additionalInfoService implements interfaces.tariff.IAdditionalInfoService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            //Gets list of all TariffCountry for a dropdown/autocomplete list
            getDropdownList(countryId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "AdditionalInfo/GetForDropdown", {
                    countryId: countryId
                });
            }

            getForDropdownWithDescription(countryId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "AdditionalInfo/GetForDropdownWithDescription", {
                    countryId: countryId
                });
            }

            getAdditionalInfoAnswerOptionList(additionalInfoId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "AdditionalInfo/GetAdditionalInfoAnswerOptionList", {
                    additionalInfoId: additionalInfoId
                });
            }

            deleteAdditionalInfoValue(additionalInfoId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "AdditionalInfo/DeleteAdditionalInfoValue", {
                    additionalInfoId: additionalInfoId
                }, {
                        query: {
                            method: 'POST'
                        }
                    });
            }
        }
    }
    angular.module("app").service("additionalInfoService", services.tariff.additionalInfoService);
}